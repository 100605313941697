import React from 'react';
import '../styles/MainSection.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

function BuyCrypto() {
  return (
    <div className="App">
      <Header />
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default BuyCrypto;
