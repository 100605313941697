import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import ThemeProvider from './context/ThemeContext';
import AuthProvider from './context/AuthContext';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Home from './pages/Home'; 
import About from './pages/About'; 
import P2p from './pages/P2p';
import Support from './pages/Support'; 
import Careers from './pages/Careers'; 
import Faqs from './pages/Faqs';

import MoneyTransfer from './pages/MoneyTransfer'; 
import BuyCrypto from './pages/BuyCrypto';
import Exchange from './pages/Exchange';

import CustomerProtectionPolicy from './pages/CustomerProtectionPolicy'; 
import AMLPolicy from './pages/AMLPolicy';
import TermsofService from './pages/TermsofService'; 
import PrivacyPolicy from './pages/PrivacyPolicy';

import Login from './pages/Login'; 
import Register from './pages/Register';
import ConfirmAccount from './pages/ConfirmAccount'; 
import ResetPassword from './pages/ResetPassword';

import Dashboard  from './pages/Dashboard'; 
import Settings   from './pages/Settings';
import Assests    from './pages/Assets'; 
import Orders   from './pages/Orders';
import Transactions from './pages/Transactions'
import Deposit    from './pages/Deposit'; 
import Withdraw   from './pages/Withdraw'; 
import Send       from './pages/Send';

import NotFound from './pages/NotFound'; 

const App = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <ThemeProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} /> {/* Landing Page */}
              <Route path="/about" element={<About />} />
              <Route path="/p2p" element={<P2p />} />
              <Route path="/support" element={<Support />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/confirm-account" element={<ConfirmAccount />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/money-transfer" element={<MoneyTransfer />} />
              <Route path="/buy-crypto" element={<BuyCrypto />} />
              <Route path="/swap" element={<Exchange />} />
              
              <Route path="/money-transfer" element={<CustomerProtectionPolicy />} />
              <Route path="/terms-of-service" element={<cAMLPolicy />} />
              <Route path="/terms-of-service" element={<TermsofService />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              <Route path="/settings" element={<Settings />} />
              <Route path="/assets" element={<Assests />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/deposit" element={<Deposit />} />
              <Route path="/withdraw" element={<Withdraw />} />
              <Route path="/send" element={<Send />} />
              <Route path="*" element={<NotFound />} /> {/* Catch-all for undefined routes */}
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  );
};

export default App;
