import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import '../styles/TransferForm.css';
import FooterCopyRights from "./FooterCopyRights";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { RotatingLines, Rings, Oval } from "react-loader-spinner";
import { tokenRequest, resetPasswordRequest} from '../requests/requests'
import GoogleIcon   from "../assets/other/google.svg";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import {constants} from '../helpers/constants'


const RegisterTransferForm = () => {

    const navigate = useNavigate();
    const [getConstants, setGetConstants] = useState({});
    const [formLoading, setFormLoading] = useState(false);
    const [resendToken, setResendToken] = useState(false);
    const [resendTokenText, setResendTokenText] = useState(false);
    const [formTokenLoading, setFormTokenLoading] = useState(false);
    const [formGoogleLoading, setGoogleFormLoading] = useState(false);
    const [authToken, setAuthToken] = useState("");
    const [componentData, setComponentData] = useState({
                                                        currencyOptions: [],
                                                        buyCurrencyFilter: [],
                                                        sellCurrencyFilter: [],
                                                        currencyCountryFlagsCollection: [],
                                                        data: {
                                                                email: "",
                                                                password: "",
                                                                token: "",
                                                                confirm_password: ""
                                                              }
                                                      });   
  
    
    //  initialize constants
    useEffect(()=> {
        const initRun = async () => {
          const config = await constants();
          setGetConstants(config);
        }
        initRun();
    }, []);
    
    // Custom option label with image and text
    const formatOptionLabel = ({ label, imageUrl }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={imageUrl} alt={label} style={{ width: 20, height: 20, marginRight: 10 }} />
          <span>{label}</span>
        </div>
    );
  
  
  
    
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
    
      const resetPassword = useMutation({
        mutationFn: (payLoad) => {
          setFormLoading(true);
          return resetPasswordRequest(payLoad);
        },
        onError: (error, variables, context) => {
          
          setFormLoading(false);
          const errors = error.response.data.errors;
          const emailNotConfirmed = _.find(errors, ["title", "Email not confirmed"]);
          if (!_.isEmpty(emailNotConfirmed)) {
            const email = variables.email;
            //navigate(`/confirm/email?email=${encodeURIComponent(email)}`);
          }
        },
        onSuccess: (data, variables, context) => {
    
          setFormLoading(false);
          const authTokens = data?.data;
          localStorage.setItem("authToken", JSON.stringify(authTokens));
          setAuthToken(authTokens);
    
        },
      });
      
      const onUserPasswordReset = (creds) => {
        resetPassword.mutate(creds);
      };
  
  
      
      const resetPasswordToken = useMutation({
        mutationFn: (payLoad) => {
          setResendToken(true);
          return tokenRequest(payLoad);
        },
        onError: (error, variables, context) => {
          
          setResendToken(false);
          setResendTokenText(true);
          const errors = error.response.data.errors;
          const emailNotConfirmed = _.find(errors, ["title", "Email not confirmed"]);
    
          if (!_.isEmpty(emailNotConfirmed)) {
            const email = variables.email;
            navigate(`/confirm/email?email=${encodeURIComponent(email)}`);
          }
        },
        onSuccess: (data, variables, context) => {
    
          setResendToken(false);
          setResendTokenText(true);
          const authTokens = data?.data;
          localStorage.setItem("authToken", JSON.stringify(authTokens));
          setAuthToken(authTokens);
    
        },
      });
      
      const onUserPasswordResetToken = (creds) => {
        resetPasswordToken.mutate(creds);
      };
  
      const contentChange= (e, theKey, trimSpace = false) => {
        try{

          let { value } = e.target;
          if(trimSpace){
            value = value.trim().replace(/\s+/g, '');
          }

          setComponentData((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              [theKey]: value,
            },
          }));
    
        } catch(e){

          console.log(e)
        }
      }

      const onUserGoogleLogin = () => {
        
      }
    
      const handleLoginSuccess = (e) => {
        console.log('success', credentialResponse)  
            
        // Decode the JWT token
        const decodedToken = jwtDecode(credentialResponse.credential);
        console.log("Decoded Token:", decodedToken);
        
        // Extract the email address
        const email = decodedToken.email;
        console.log("User Email:", email);    
        const loginData = { accessType: 'mail', email: email, emailData: decodedToken};
        resetPassword.mutate(loginData);
        
      }
    
      
      const handleLoginFailure = (e) => {
        
        console.log('Error', e)  
      }
    
  return (
    <div className="card-login">
      <form className="form">

        <div className="text-center-content">
           <h3 className="text-center"> Create Your PeerPesa Account</h3> 
        </div>   


    
        <div className="form-inputs">
          <label> Email  </label>
          <div className="selection-payment-method clearfix group">
            <input type="text" 
                   className="selection-other"
                   value={componentData.data.email}
                   placeholder="Enter your email"
							     name="email"
                   {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email address",
                    },
                   })}
                   onChange={(e) => contentChange(e, 'email', true)}  />
          </div>
          {errors.email?.type === "required" && (<span className="main-input-error">
            {errors.email.message}
          </span>)}
          {errors.email?.type === "pattern" && (<span className="main-input-error">
            {errors.email.message}
          </span>)}
        </div>

        <div className="form-inputs">
          <label> Verification Token  </label>
          <div className="flexing_content flex_container reset-token-width">

            <div className="selection-payment-method clearfix group width-40">
              <input  type="text" 
                      className="selection-other"
                      value={componentData.data.token}
                      placeholder="Enter verification token"
                      name="token"
                      {...register("token", {
                        required: false
                      })}
                      onChange={(e) => contentChange(e, 'token')}  />
            </div>
           
            <button className="send-button width-55" onClick={handleSubmit(onUserPasswordResetToken)}>
              <span  className={(resendToken)? 'hidden':''}>
                {resendTokenText? 'Resend Token':'Send Token'}
              </span>
           
              <Oval
                   visible={resendToken}
                   height="20"
                   width="20"
                   color="#ffffff"
                   ariaLabel="oval-loading"
                   wrapperStyle={{}}
                   wrapperClass=""
                  />   
            </button>
            {errors.token?.type === "required" && (
              <span className="main-input-error clearfix">
                Email verification token is required
              </span>
            )}
          </div>
        </div>

        {resendTokenText? <>
                  
                <div className="form-inputs">
                  <label> New Password </label>
                  <div className="selection-payment-method clearfix group">
                    <input type="password" 
                           className="selection-other"
                           value={componentData.data.password}
                           placeholder="Enter password"
                           name="password"
                           {...register("password", {
                             required: false
                           })}
                           onChange={(e) => contentChange(e, 'password')}  />
                  </div>
                  {errors.password?.type === "required" && (
                      <span className="main-input-error clearfix">
                        Password is required
                      </span>
                    )}
                </div>
                
                <div className="form-inputs">
                  <label> Confirm Password </label>
                  <div className="selection-payment-method clearfix group">
                    <input type="password" 
                           className="selection-other"
                           value={componentData.data.confirm_password}
                           placeholder="Enter password"
                           name="confirm_password"
                           {...register("confirm_password", {
                             required: false
                           })}
                           onChange={(e) => contentChange(e, 'confirm_password')}  />
                  </div>
                  {errors.confirm_password?.type === "required" && (
                      <span className="main-input-error clearfix">
                        Confirm password is required
                      </span>
                    )}
                </div>
  
                <div className="text-center-content-2">
                  <p className="text-left">
                      By clicking 'Create Account' means you accept <Link to="/terms-of-use" className="nav-link">PeerPesa Terms of Service</Link>
                  </p> 
                </div>
        
                <button className="send-button" onClick={handleSubmit(onUserPasswordReset)}>
                      <span  className={(formLoading)? 'hidden':''}>
                        Create Account
                      </span>
                      <Oval
                           visible={formLoading}
                           height="20"
                           width="20"
                           color="#ffffff"
                           ariaLabel="oval-loading"
                           wrapperStyle={{}}
                           wrapperClass=""
                          />  
                </button>

        </>:''}

        

        <div className="text-center-content-200">
           <p className="text-center">
              or
           </p> 
        </div>
                
        <div className="text-center border_google_register">
            <GoogleOAuthProvider clientId={getConstants?.google_id}>
              <GoogleLogin
                    theme="filled_black"
                    size="medium"
                    shape="circle" 
                    onSuccess={handleLoginSuccess}
                    onError={handleLoginFailure}
                    className="google_login_btn" />
            </GoogleOAuthProvider>
        </div> 
        <div className="text-center-content-2">
           <p className="text-center">
                Have an Account Already? <Link to="/login" className="nav-link">Login here</Link>
           </p> 
        </div>

        <FooterCopyRights />

        
      </form>
    </div>
  );
};

export default RegisterTransferForm;
