import React from 'react';
import '../styles/HeroSection.css';
import TransferForm from './TransferForm';
import Footer from './Footer';
import WelcomeStatement from './WelcomeStatement';


import iconIcon     from "../assets/other/simple.svg";
import icon2Icon    from "../assets/other/build.svg";
import icon3Icon    from "../assets/other/support.svg";
import banner1      from "../assets/other/banner_1.svg";
import banner2      from "../assets/other/banner_2.svg";
import usdtBanner   from "../assets/other/usdt_banner.svg";
import usdctBanner  from "../assets/other/usdc_banner_icon.svg";
import xlmBanner    from "../assets/other/xlm_banner_icon.svg";
import cusdBanner   from "../assets/other/cusd.svg";

import stellarIcon   from "../assets/other/stellar.svg";
import togggleIcon   from "../assets/other/togggle.svg";
import celoIcon      from "../assets/other/celo.svg";
import KotaniPayIcon from "../assets/other/kotanipay.svg";
import flutterwaveIcon from "../assets/other/flutterwave.png";


import celoTwoIcon  from "../assets/other/celo_two.svg";
import prezentiIcon  from "../assets/other/prezenti.svg";



const HeroSection = () => {
  return (
   <>      
    <section className="section flexing_content flex_container">
      
      <div className="column_60">
        <WelcomeStatement />
      </div>
      <div className="column_40">
        <TransferForm />
      </div>
    </section>
      
    <div className="column_100">
      <p className="tagline  text-center">
         Empowering Your <span className="highlight-lower">Cross-Border Transactions</span>, Anytime, Anywhere
      </p>
    </div>

    <div className="column_100 outer-middle-section flexing_content flex_container">
      <div className="block-middle-section column_33_3 margin-bottom-30"> 
        <div className="block-middle-section-inner dark-blue-background group clearfix">
          <div className="the-icon-background"><img src={iconIcon} alt="1" /></div> 
          <div className="the-text-background group clearfix">
            <p className="green-text">Simplify Every Transaction Fast, Secure, and Effortless</p>
          </div>
        </div> 
      </div>
      
      <div className="block-middle-section column_33_3 margin-bottom-30">
        <div className="block-middle-section-inner dark-green-background  group clearfix">
          <div className="the-icon-background"><img src={icon2Icon} alt="2" /></div>  
          <div className="the-text-background group clearfix">
            <p  className="white-text">Built on Blockchain Guaranteed Security for Every Transaction</p>
          </div>
        </div> 
      </div>

      <div className="block-middle-section column_33_3 margin-bottom-30">
        <div className="block-middle-section-inner dark-blue-background group clearfix">
          <div className="the-icon-background"><img src={icon3Icon} alt="2" /></div>
          <div className="the-text-background group clearfix">
            <p className="white-text">Around the Clock Support We’re Here for You 24/7</p>
          </div>
        </div> 
      </div>

    </div>

    <div className="column_100">
      <p className="tagline  text-center"> Top Features </p>
    </div>

    
    <div className="column_100 outer-middle-section flexing_content flex_container">


      <div className="block-middle-section column_100 margin-bottom-30">
        <div className="block-middle-section-inner banner-blocker group clearfix   
                        flexing_content flex_container">
          
          <div className="the-icon-background-relative column_40">
            <img src={banner1} alt="2"  className="image-one"/>
          </div>  

          <div className="the-text-background-main group  column_60">
            <h4  className="blue-text text-left"><strong>Money Transfer</strong></h4>
            <p  className="blue-text">Global Payments Made Easy with Stablecoins </p>
          </div>

        </div> 
      </div>

      <div className="block-middle-section column_100 margin-bottom-30">
        <div className="block-middle-section-inner dark-black-background group 
                        clearfix flexing_content flex_container">

          <div className="the-icon-background-relative column_40">
            <img src={banner2} alt="2" className="image-one"/>
            <img src={usdtBanner} alt="2" className="image-two" />
            <img src={usdctBanner} alt="2" className="image-three" />
            <img src={xlmBanner} alt="2" className="image-four" />
            <img src={cusdBanner} alt="2" className="image-five" />
          </div>  

          <div className="the-text-background-main group  column_60">
            <h4  className="green-text text-left"><strong>Buy Crypto</strong></h4>
            <p  className="green-text"> Instantly Buy & Send Money with XLM, USDT, and
                                        cUSD to mobile money or your bank account! </p>
          </div>
        </div> 
      </div>

    </div>








    <div className="column_100">
      <p className="tagline  text-center"> Our Partners </p>
    </div>

    <div className="column_100 outer-middle-section flexing_content flex_container">
      <div className="column_15_logo">
        <div className="the-icon-partner-relative yellow-background-partner">
          <img src={celoIcon} alt="2"  className="image-partner-logo"/>
        </div>  
      </div>
      <div className="column_15_logo">
        <div className="the-icon-partner-relative border-black">
          <img src={stellarIcon} alt="2"  className="image-partner-logo"/>
        </div>  
      </div>
      <div className="column_15_logo">
        <div className="the-icon-partner-relative border-black">
          <img src={prezentiIcon} alt="2"  className="image-partner-logo"/>
        </div>  
      </div>
      <div className="column_15_logo">
        <div className="the-icon-partner-relative blue-background-partner">
          <img src={togggleIcon} alt="2"  className="image-partner-logo"/>
        </div>  
      </div>
      {/* <div className="column_15_logo">
        <div className="the-icon-partner-relative border-black">
          <img src={celoTwoIcon} alt="2"  className="image-partner-logo"/>
        </div>  
      </div> */}
      <div className="column_15_logo">
        <div className="the-icon-partner-relative border-black white-background">
          <img src={flutterwaveIcon} alt="2"  className="image-partner-logo flutterwave"/>
        </div>  
      </div>

    </div>  

    <div>
      <Footer />
    </div>

   </>      
  );
};

export default HeroSection;
