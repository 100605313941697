import CryptoJS from 'crypto-js';
import forge from "node-forge";

const thePublicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAmJJ90w5vt/mBKpc3BLWc
lNHWHge+s3j7asWTFUCLASmgzRHHj9Lm3wZsLVz3dG0Xn/0jZzyFkUoLQ0cy80Bk
4JNUEKl65Xck3EQSZVaeZwjZcBoAwA4wYJHXdbxUOrXPsnEPShIG4HjT0uapxjsh
qtfbQTOsG+ADydzqTF732qhE1K3yTps9/1YrftumQLyn9EkMILC/ARXCta20JsVI
JdaURHkEDl2oEK8WCozqhTRr3fVCg9rUmAwI7kMI9RWFtuLlUvwBzFeQjSqKS3Fv
BbE6JT3bE+sK8pBr+yNPcpCP+9/0hdwScNo92+jMDpNM7VCwQred1d8/93s8RvZk
gU2g0ulpCNHySSYQ2fcvBfRSO8vZPt79SL+VNXU6HwCcsCTx03IsCo1fjNXBJvZj
Yybaux/WZenU5XEPxLFmmIlXJPku1UV9tQh/j8ulJiuzbzBF0QcvjwyJ8YuyW9Qq
jWU4HTsOTUlLx6OIitSg2A7wge4WkqEhpGGriKfYNMXn4GDbaAqe8kIZ/dF/UuM0
sdx4E3VjE6cO2pFxlx9qv6JHEeSbDvWPNJq+JPMCwJRGE8OJzcF/7j1r3RXCXa4T
SmKU0KY2xNfd7WxrJ/+ow17yHh91QwctTkF5lG/psB49+dmXjyvtOkufANzFSoZT
/8XQKNSbPrbPHrHSI/OJRncCAwEAAQ==
-----END PUBLIC KEY-----`;

// read from public key file
const encryptData = (data, secretKey = process.env.DATA_SECRET_KEY, iv = process.env.DATA_IV_KEY) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(secretKey), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

// read from public key file
const getPublicKey = () => {
  try{
    return publicKey;
  }catch(e){
    console.log(e);
  }
};


// Generate the IV for each post made
const generateDataIV = () => {
  return forge.random.getBytesSync(16);
};

// encrypt with publoc key
const encryptWithPublicKey = (iv, publicKey) => {

  const pem = forge.pki.publicKeyFromPem(publicKey);
  const encryptedIV = pem.encrypt(iv, "RSA-OAEP", {
    md: forge.md.sha256.create(),
  });
  return forge.util.encode64(encryptedIV); 
};

const encryptPayLoad = async (data) => {

  // Loading public key
  const publicKey     = thePublicKey; 
  // Generate IV
  const iv            = await generateDataIV(); 

  // Encrypt IV with RSA
  const encryptedIV   = await encryptWithPublicKey(iv, publicKey); 

  const key = forge.util.createBuffer(iv); 
  const cipher = forge.cipher.createCipher("AES-CBC", key);
  cipher.start({ iv });
  cipher.update(forge.util.createBuffer(JSON.stringify(data)));
  cipher.finish();
  return {
            iv: encryptedIV,
            data: forge.util.encode64(cipher.output.bytes())
         };
};
export { encryptPayLoad };

