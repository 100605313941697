import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import '../styles/TransferForm.css';
import FooterCopyRights from "./FooterCopyRights";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { RotatingLines, Rings, Oval } from "react-loader-spinner";
import {loginAccount} from '../requests/requests'
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {constants} from '../helpers/constants'
import ReCAPTCHA from "react-google-recaptcha";


const LoginTransferForm = () => {

  const navigate = useNavigate();
  const [getConstants, setGetConstants] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [formGoogleLoading, setGoogleFormLoading] = useState(false);
  const [authToken, setAuthToken] = useState("");
	const [captchaValue, setCaptchaValue] = useState(null);
  const [componentData, setComponentData] = useState({
                                                                  currencyOptions: [],
                                                                  buyCurrencyFilter: [],
                                                                  sellCurrencyFilter: [],
                                                                  currencyCountryFlagsCollection: [],
                                                                  data: {
                                                                    email: "",
                                                                    password: ""
                                                                  }
                                                              });    
  
  useEffect(()=> {
    const initRun = async () => {
      const config = await constants();
      setGetConstants(config);
    }
    initRun();
  }, []);
                                                              
  const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const login = useMutation({

		mutationFn: (creds) => {
      setFormLoading(true);
      return loginAccount(creds);
		},
		onError: (error, variables, context) => {
      
      setFormLoading(false);
			const errors = error.response.data.errors;
			const emailNotConfirmed = _.find(errors, ["title", "Email not confirmed"]);

			if (!_.isEmpty(emailNotConfirmed)) {
				const email = variables.email;
				navigate(`/confirm/email?email=${encodeURIComponent(email)}`);
			}
		},
		onSuccess: (data, variables, context) => {

      setFormLoading(false);
			const authTokens = data?.data;
			localStorage.setItem("authToken", JSON.stringify(authTokens));
			setAuthToken(authTokens);

		},
	});
  
	const onUserLogin = (creds) => {

    creds = { ... creds, accessType: 'password'};
    if (!captchaValue) {
		  return alert("Please complete the CAPTCHA");
		}
    login.mutate(creds);
	};


  const contentChange= (e, theKey, trimSpace = false) => {
    try{
      
      let { value } = e.target;
      if(trimSpace){
            value = value.trim().replace(/\s+/g, '');
      }
      setComponentData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [theKey]: value,
        },
      }));

    } catch(e){
      console.log(e)
    }
  }
  
  // Custom option label with image and text
  const formatOptionLabel = ({ label, imageUrl }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={imageUrl} alt={label} style={{ width: 20, height: 20, marginRight: 10 }} />
        <span>{label}</span>
      </div>
  );

  const onUserGoogleLogin = () => {
        
  }

  const handleLoginSuccess = (credentialResponse) => {
    console.log('success', credentialResponse)  
    
    // Decode the JWT token
    const decodedToken = jwtDecode(credentialResponse.credential);
    console.log("Decoded Token:", decodedToken);

    // Extract the email address
    const email = decodedToken.email;
    const loginData = { accessType: 'mail', email: email, emailData: decodedToken};
    login.mutate(loginData);
  }

  
  const handleLoginFailure = (e) => {

    console.log('Error', e)  
    toast.error(e.message);
    return;
  }

  const handleCaptchaChange = (value) => {
		setCaptchaValue(value);
	};

  return (
    <div className="card-login">
      <form className="form">

        <div className="text-center-content">
           <h3 className="text-center">Log into Your PeerPesa Account</h3> 
        </div>   
        <div className="form-inputs">
          <label> Email</label>
          <div className="selection-payment-method clearfix group">
            <input type="text"
                   value={componentData.data.email}
                   className="selection-other"
                   placeholder="Enter your email"
							     name="email"
                   {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email address",
                    },
                   })}
                   onChange={(e) => contentChange(e, 'email', true)} />
          </div>
          {errors.email?.type === "required" && (<span className="main-input-error">
            {errors.email.message}
          </span>)}
          {errors.email?.type === "pattern" && (<span className="main-input-error">
            {errors.email.message}
          </span>)}
        </div>
        <div className="form-inputs">
          <label> Password </label>
          <div className="selection-payment-method clearfix group">
            <input type="password" 
                   value={componentData.data.password} 
                   className="selection-other"  
                   placeholder="Enter your password"
                   {...register("password", { required: true })} 
                   onChange={(e) => contentChange(e, 'password')}

                   />
          </div>
          {errors.password?.type === "required" && (
								<span className="main-input-error">
									{/* <Info className="login_input_error_icon" /> */}
                  Password is required
								</span>
						  )} 
        </div>

        <div className="text-center-content-2">
           <p className="text-left">
              Forgot password? <Link to="/reset-password" className="nav-link">Reset it here</Link>
           </p> 
        </div>
        <div className="text-center-content-2">
          <ReCAPTCHA
                  sitekey="6LcHVL4qAAAAALcGmdbmVPxPF9_48m35Ce50JdeO" // Replace with your site key // server 6LcHVL4qAAAAAAnwbIBiR1RmfOvlHLnCDzO1idVa
                  onChange={handleCaptchaChange}
                />
         </div>      

        
        <button className="send-button" onClick={handleSubmit(onUserLogin)}>
           <span  className={(formLoading)? 'hidden':''}>Login</span>
           <Oval
                          visible={formLoading}
                          height="20"
                          width="20"
                          color="#ffffff"
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          />                        
        </button>

        
        <div className="text-center-content-200">
          <p className="text-center">
            or
          </p> 
        </div>
        <div className="text-center border_google_register">
          <GoogleOAuthProvider clientId={getConstants?.google_id}>
            <GoogleLogin
                          theme="filled_black"
                          size="medium"
                          shape="circle" 
                          onSuccess={handleLoginSuccess}
                          onError={handleLoginFailure}
                          className="google_login_btn" />

          </GoogleOAuthProvider>
        </div> 
        
        <div className="text-center-content-2">
           <p className="text-center">
             Dont Have An Account? <Link to="/register" className="nav-link">Signup here</Link> 
           </p> 
        </div>
      
        <FooterCopyRights />
        
      </form>
    </div>
  );
};

export default LoginTransferForm;
