import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for createRoot
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store/store';
import { ToastContainer } from 'react-toastify'
import App from './App';
import './styles/Header.css';
import 'react-toastify/dist/ReactToastify.css'

// Create a QueryClient instance
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root')); // Use createRoot
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
          <ToastContainer />  
        </PersistGate>
      </QueryClientProvider>
    </Provider>  
  </React.StrictMode>
);
