import { postRequest, deleteRequest, putRequest, getRequest } from './api/api';
export const registerAccount = async (payload) => {
  try {
    const response = await postRequest('/register', payload);
    console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const loginAccount = async (payload) => {
  try {
    const response = await postRequest('/login', payload);
    console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const tokenRequest = async (payload) => {
  try {
    const response = await postRequest('/token', payload);
    console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const resetPasswordRequest = async (payload) => {
  try {
    const response = await postRequest('/reset/password', payload);
    console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};


export const verifyTokenRequest = async (payload) => {
  try {
    const response = await postRequest('/token/verification', payload);
    console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const profileDetails = async (payload) => {
  try {
    const response = await getRequest('/profile');
    console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const rejenerateJWT = async (payload) => {
  try {
    const response = await postRequest('/jwt/reverification', payload);
    console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};


// export const handleDelete = async (payload) => {
//   try {
//     const response = await deleteRequest('/resource/123');
//     console.log('DELETE response:', response);
//     setData(response);
//   } catch (error) {
//     console.error('DELETE error:', error);
//   }
// };

// export const handlePut = async (payload) => {
//   try {
//     const payload = { name: 'Updated Sample', value: 84 };
//     const response = await putRequest('/resource/123', payload);
//     console.log('PUT response:', response);
//     setData(response);
//   } catch (error) {
//     console.error('PUT error:', error);
//   }
// };
