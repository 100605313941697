import React from 'react';
import '../styles/MainSection.css';
import Header from '../components/Header';

import TransferForm from '../components/TransferForm';
import Footer from '../components/Footer';

function Exchange() {
  return (
    <div className="App">

      <Header />

      <div className="sell-currency-rating flexing_content flex_container container-rating">
        <div></div>

        <div  className="trading-exchange"><TransferForm /></div>
        
        <div></div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Exchange;
