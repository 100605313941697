import React, {useState, useEffect} from 'react';
import '../styles/HeroSection.css';
import RegisterTransferForm from './RegisterTransferForm';
import RegisterWelcomeStatement from './RegisterWelcomeStatement';


const FooterCopyRights = () => {

  const [data, setDate] = useState("");
  useEffect(() => {
    setDate(new Date().getFullYear());
  }, [])

  
  return (
   <>      
      <div className="text-center-content-2">
        <p className="text-center">
          &copy; {data} Peerpesa. All rights reserved.
        </p> 
      </div>
   </> 
  );
};

export default FooterCopyRights;
