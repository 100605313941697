import React, {useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/Footer.css';
import { FaCoins } from 'react-icons/fa';
import logoIcon   from "../assets/logo.png";
import FooterCopyRights from "./FooterCopyRights";

import linkedInIcon   from "../assets/other/linkedIn.svg";
import facebookIcon   from "../assets/other/facebook.svg";
import youtubeIcon   from  "../assets/other/youtube.jpg";
import tiktokIcon   from "../assets/other/tiktok.svg";
import instagramIcon   from "../assets/other/instagram.svg";
import xIcon   from "../assets/other/x.svg";

const Footer = () => {
  const [data, setDate] = useState("");

  useEffect(() => {
    setDate(new Date().getFullYear());
  }, [])


  const openSocial = (url) => {
    window.open(url, '_blank');
    return;
  }

  return (
    <div className="footer group clearfix">

      <div className="footer-logo">
        <img src={logoIcon} alt="peerpesa" className="footer-snap" />   
      </div>

      <div className="column_100 flexing_content flex_container group clearfix">
           
        <div className="column_25 margin-bottom-30">
          <div className="column_100 footer_content">
            <h3> Quick Links </h3>
            <ul>
              <li><Link  to="/about" className="nav-link">About</Link></li>
              <li><Link  to="/support" className="nav-link">Support</Link></li>
              <li><Link  to="/faq" className="nav-link">FAQ</Link></li>
              <li><Link  to="/careers" className="nav-link">Careers</Link></li>
            </ul>
          </div>  
        </div>

        <div className="column_25 margin-bottom-30">
          <div className="column_100 footer_content">
          <h3> Products </h3>
            <ul>
              <li><Link  to="/money-transfer" className="nav-link">Money transfer</Link></li>
              <li><Link  to="/buy-crypto" className="nav-link">Buy Crypto</Link></li>
              <li><Link  to="/swap" className="nav-link">Swap</Link></li>
            </ul>
          </div>   
        </div>

        <div className="column_25 margin-bottom-30">
          <div className="column_100 footer_content">
          <h3> Legal </h3>
            <ul>
              <li><Link  to="/customer-protection-policy" className="nav-link">Customer Protection Policy</Link></li>
              <li><Link  to="/aml-policy" className="nav-link">AML Policy</Link></li>
              <li><Link to="/terms-of-service" className="nav-link">Terms of Service</Link></li>
              <li><Link to="/" className="nav-link">Privacy Policy</Link></li>
            </ul>
          </div> 
        </div>

        <div className="column_25 margin-bottom-30">
          <div className="column_100 footer_content">
          <h3> Our Community </h3>
          <ol>
              <li> <img src={linkedInIcon} alt="linked" className="footer-social-snap" onClick={() => openSocial('https://www.linkedin.com/company/peerpesa/')} /> </li>
              <li> <img src={youtubeIcon} alt="Youtube" className="footer-social-snap footer-social-snap-youtube" onClick={() => openSocial('https://www.youtube.com/@PeerPesa')} /> </li>
              <li> <img src={tiktokIcon} alt="tiktok" className="footer-social-snap" onClick={() => openSocial('https://www.tiktok.com/@peerpesa')} /> </li>
              <li> <img src={instagramIcon} alt="instagram" className="footer-social-snap" onClick={() => openSocial('https://www.instagram.com/peer_pesa')} /> </li>
              <li> <img src={xIcon} alt="x" className="footer-social-snap" onClick={() => openSocial('https://x.com/peer_pesa')} /> </li>
          </ol>
          </div> 
        </div>

      </div>

      <FooterCopyRights />

      <div className="column_100"></div>
    </div>
  );
};

export default Footer;
