import React from 'react';
import '../styles/HeroSection.css';
import ResetPasswordWelcomeStatement from './ResetPasswordWelcomeStatement';
import ResetPasswordTransferForm from './ResetPasswordTransferForm';

const ResetPasswordSection = () => {
  return (
    <>      
     <section className="dark-section flexing_content flex_container dark-section-login margin-top-20">
       <div className="column_60">
        <ResetPasswordWelcomeStatement />
       </div>
       <div className="column_40 nopadding">
         <ResetPasswordTransferForm />
       </div>
     </section>
    </>      
  );
};

export default ResetPasswordSection;
